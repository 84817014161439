<template>
<h1 class="page-header">
Manage Countries
</h1>
<hr class="mb-4" />

<div class="card  bg-default border-primary  mb-4" id="newbannerdata">
    <div class="card-header"><h5 class="card-title">Add New Country Database</h5></div>
    <div class="card-body">
        <div class="form-group row mb-3">
        
        <div class="col-sm-5">
            <label class="form-label" for="exampleFormControlInput1">Country Title</label>
            <input type="email" v-model="forminput.title" class="form-control" id="exampleFormControlInput1" placeholder="Enter  Title">
            <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>
        </div>

        <div class="col-sm-5">
            <label class="form-label" for="exampleFormControlInput1">Zip Code </label>
            <input type="email" v-model="forminput.zipcode" class="form-control" id="exampleFormControlInput1" placeholder="Enter Zip Code">
            <i class="text-warning" v-if="formerror.caption">{{ formerror.zipcode }}</i>
        </div>

        <div class="col-sm-2 mt-4">
            <button type="button" class="btn btn-indigo btn-block" @click.prevent="savebannerdata">Submit </button>
        </div>
        </div>


    </div>
</div>


<div class="card  bg-default border-primary  mb-4" v-if="country" id="updatecountrydata">
    <div class="card-header"><h5 class="card-title">Update Country Data</h5></div>
    <div class="card-body">
        <div class="form-group row mb-3">
        
        <div class="col-sm-5">
            <label class="form-label" for="exampleFormControlInput1">Country Title</label>
            <input type="email" v-model="forminput.title" class="form-control" id="exampleFormControlInput1" placeholder="Enter  Title">
            <i class="text-warning" v-if="formerror.title">{{ formerror.title }}</i>
        </div>

        <div class="col-sm-5">
            <label class="form-label" for="exampleFormControlInput1">Zip Code </label>
            <input type="email" v-model="forminput.zipcode" class="form-control" id="exampleFormControlInput1" placeholder="Enter Zip Code">
            <i class="text-warning" v-if="formerror.caption">{{ formerror.zipcode }}</i>
        </div>

        <div class="col-sm-2 mt-4">
            <button type="button" class="btn btn-indigo btn-block" @click.prevent="savebannerdata">Submit </button>
        </div>
        </div>


    </div>
</div>



<div class="row">

<div class="card">
<ul class="nav nav-tabs nav-tabs-v2 px-4">
<li class="nav-item me-3"><a href="#allTab" class="nav-link active px-2" data-bs-toggle="tab">All</a></li>
<li class="nav-item me-3"><a href="#publishedTab" class="nav-link px-2" @click.prevent="changefilterlist('active')">Active </a></li>
<li class="nav-item me-3"><a href="#expiredTab" class="nav-link px-2" @click.prevent="changefilterlist('suspended')">Suspended</a></li>
</ul>


<div class="tab-content p-4">
<div class="tab-pane fade show active" id="allTab">

<div class="input-group mb-4">
<div class="flex-fill position-relative">
<div class="input-group">
<input type="text" v-model.trim="search" class="form-control ps-35px" placeholder="Search by Title" />
<div class="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style="z-index: 1020;">
<i class="fa fa-search opacity-5"></i>
</div>
</div>
</div>
</div>


<div class="table-responsive">
<table class="table table-hover text-nowrap">
<thead>
<tr>
<th class="border-top-0 pt-0 pb-2">#</th>
<th class="border-top-0 pt-0 pb-2">Country Title</th>
<th class="border-top-0 pt-0 pb-2">Districts</th>
<th class="border-top-0 pt-0 pb-2">Counties</th>
<th class="border-top-0 pt-0 pb-2">Divisions</th>
<th class="border-top-0 pt-0 pb-2">Parishes</th>
<th class="border-top-0 pt-0 pb-2">Villages</th>
<th class="border-top-0 pt-0 pb-2">Population</th>
<th class="border-top-0 pt-0 pb-2">Status</th>
<th class="border-top-0 pt-0 pb-2">Action</th>
</tr>
</thead>
<tbody v-if="countries">

<tr v-for="(item, index) in filteredlist" :key="item.id" style="cursor:pointer !important" >

    <td class="align-middle">{{ index + 1}}</td>
    <td class="align-middle"><router-link :to="{name:'countrydetails', params:{id:item.id}}">{{ item.title }}</router-link></td>
    <td class="align-middle" >{{ item.districts_count }}</td>
    <td class="align-middle" >{{ item.counties_count }}</td>
    <td class="align-middle" >{{ item.divisions_count }}</td>
    <td class="align-middle" >{{ item.parishes_count }}</td>
    <td class="align-middle" >{{ item.villages_count }}</td>
    <td class="align-middle" >{{ item.collections_count }}</td>
    <td class="align-middle" >
        <button v-if="item.status == 1" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-teal  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Suspend</button>
        <button v-if="item.status == 0" @click.prevent="changeitemstatusbtn(item.id)" class="badge btn btn-indigo  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2">Unsuspend</button>
    </td>


    <td class="align-middle">
  
    <button @click.prevent="updateitemact(item.id)"  class="badge btn btn-warning  bg-opacity-25 px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center ml-2" style="margin-right:6px">Update</button>
   </td>

</tr>




</tbody>
</table>
</div>


<div class="d-md-flex align-items-center" v-if="countries">
<div class="me-md-auto text-md-left text-center mb-2 mb-md-0">
Showing {{ countries.data.length}} to {{ countries.per_page}} of {{ countries.total}} entries
</div>
<ul class="pagination mb-0 justify-content-center">

<li class="page-item" v-for="(link, index) in countries.links" :key="link.id">
    <a v-if="countries.last_page >= index && index != 0 " @click.prevent="getpageteachers(index )" class="page-link" href="#">{{ index}}</a>
</li>

</ul>
</div>



</div>
</div>
</div>



</div>


</template>
<script>
import {mapGetters, mapActions } from 'vuex'
export default {

    data(){
        return{
            forminput:{
                title: '',
                zipcode:'',
            },
            formerror:{
                title: '',
                zipcode:'',
            },

            lastpage:'',
            currentpage:'',
            nextpage:'',
            filtertype:'',
            search:''
            
        }
    },



     watch:{
		search(){
			if(this.search.length){
				if(this.search.length > 3){
					this.searchbtncountries()
				}else{
					this.getallcountries()
				}

			}else{
				this.getCountrylist()
			}
		}
	},



     computed:{
        ...mapGetters(['msg', 'error','pageloader','loading', 'countries', 'country']),

            filteredlist(){
                if(this.filtertype == 'active'){
                    return this.countries.data.filter(co => co.status == 1)
                }else if(this.filtertype == 'suspended'){
                    return this.countries.data.filter(co => co.status == 2)
                }else {
                    return this.countries.data
                }
            },
        },


    methods:{
        ...mapActions(['saveCountry','getCountrylist', 'getcountry','updateCountry','getserachedcountries','changecountrystatus']),

        getserachlist(){
              if(this.search == ''){
              }else{
                  let data = {
                    search: this.search
                    }
                    
                    this.getserachedcountries(data);
              }   
        },



        countryformdata(){
            if(this.forminput.title == ''){
                this.formerror.title = 'Country Name is Required';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.forminput.zipcode == ''){
                this.formerror.zipcode = 'Country zipcode is Required';
                setTimeout(() => this.formerror.zipcode = '', 3000)
            }else{
            let data = {
                title:this.forminput.title,
                zipcode:this.forminput.zipcode
            }
            this.saveCountry(data).then(()=>{
                 this.getCountrylist();
                this.forminput.title = '';
                this.forminput.zipcode = '';
                
            })
            }
           
        },

        getcountryforupdate(id){
            this.getcountry(id).then(()=>{
                this.showupdateform();
            })

        },


        changeitemstatusbtn(id){
            this.changecountrystatus(id).then(()=>{
     
            })
        },


        updateforminput(id){
            if(this.country.title == ''){
                this.formerror.title = 'Country Name is Required';
                setTimeout(() => this.formerror.title = '', 3000)
            }else if(this.country.zipcode == ''){
                this.formerror.zipcode = 'Country zipcode is Required';
                setTimeout(() => this.formerror.zipcode = '', 3000)
            }else{
            let data = {
                title:this.country.title,
                zipcode:this.country.zipcode
            }
            this.updateCountry({data, id}).then(()=>{
                 this.getCountrylist();
                this.cancelprocess();
            })
            }
           
        },

        showcreateformform(){
            $('#updateoldform').addClass('hideall')
            $('#createnewbtn').addClass('hideall')
            $('#createnewform').removeClass('hideall')
            $('#closebtn').removeClass('hideall')
        },

        showupdateform(){
            $('#updateoldform').removeClass('hideall')
            $('#createnewbtn').addClass('hideall')
            $('#createnewform').addClass('hideall')
            $('#closebtn').removeClass('hideall')
        },


        cancelprocess(){
            $('#updateoldform').addClass('hideall')
            $('#createnewbtn').removeClass('hideall')
            $('#createnewform').addClass('hideall')
            $('#closebtn').addClass('hideall')
        },
    },

    

    created(){
        this.getCountrylist();
    }

}
</script>